@import '~react-image-gallery/styles/css/image-gallery.css';

.react-datepicker__input-container input {
  width: 100%;
  padding: 0.4rem;
}

/* .react-datepicker__input-container { */
/* width: 90%; */
/* } */

.font14 {
  font-size: 14px;
  /* text-align: center; */
  font-weight: 500;
}

.font12 {
  font-size: 12px;
}

.font11 {
  font-size: 11px;
}

.input {
  padding: 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ced4da;
  outline: none;
}

.padding5 {
  padding: 5px;
}

.input-container-nav {
  background-color: #f0f3f5;
  padding: 0.6rem;
  border-radius: 4px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-green {
  width: 6px;
  height: 6px;
  background: RGB(133, 186, 89);

  border-radius: 80%;
  margin-right: 10px;
}

.circle-red {
  width: 6px;
  height: 6px;
  background: red;
  border-radius: 80%;
  margin-right: 10px;
}

.circle-purpale {
  width: 8px;
  height: 8px;
  background: RGB(227, 221, 246);
  border-radius: 80%;
  margin-right: 10px;
}

.flex1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputRounded .MuiOutlinedInput-root {
  border-radius: 50px;
}

*,
body,
html {
  font-family: Roboto;
}

.MuiInputBase-root,
.MuiInput-root .MuiSelect-select:focus {
  background-color: none;
}

.dots {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.date-picker {
  border: 1px solid #ced4da;
  outline: none;
  height: 38px;
  border-radius: 5px;
}

.date-picker:focus {
  border: 1px solid #ced4da;
  outline: none;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #0b1120;
}

*::-webkit-scrollbar-track {
  background-color: #e2e8f0;
}

*::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #94a3b8;
}

.react-tel-input {
  background-color: rgba(249, 249, 249, 1);
}

.react-tel-input .form-control {
  width: 100%;
  height: 45px;
  background: none;
  border: none;
}

.react-tel-input .flag-dropdown {
  background-color: rgba(249, 249, 249, 1);
  border: none !important;
}

.react-tel-input .selected-flag {
  border-radius: 10px;
}

/* #root {
	zoom: 95%;

}  */

.supervisor-box {
  width: 10px;
  height: 10px;
  background-color: #9872ef;
  margin-left: 10px;
  border-radius: 2px;
}
.commited-box {
  width: 10px;
  height: 10px;
  background-color: #7e7e7e;
  margin-left: 10px;
  border-radius: 2px;
}
.helper-box {
  width: 10px;
  height: 10px;
  background-color: rgba(52, 152, 183, 1);
  margin-left: 10px;
  border-radius: 2px;
}
.skilled-box {
  width: 10px;
  height: 10px;
  background-color: RGB(133, 186, 89);
  margin-left: 10px;
  border-radius: 2px;
}

.border-datepicker {
  /* border-image-slice: 2; */
  border-radius: 5px;
  border: 1px solid #adbac380;
  z-index: 100;
  font-size: 12px !important;
}

.coursel-container-content {
  border: 1px solid #ddd;
  width: 50%;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.coursel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.MuiFormLabel-root {
  font-family: Roboto !important;
}

.inputClass,
.inputStyle {
  font-family: 'Roboto';
}

.MuiInputBase-input {
  font-family: 'Roboto' !important;
  font-weight: 600 !important;
}

.textArea {
  height: 133px;
  padding: 10px;
  width: 100%;
  background-color: transparent;
  margin-left: 20px;
}

.calendarWrap {
  display: inline-block;
  position: relative;
  width: 100%;
  z-index: 1000;
}

.calendarElement {
  /* width: "50%"; */
  /* border: 2px solid red; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
}

input.inputBox {
  width: 100%;
  font-size: 12px;
  /* padding: 5px 8px 4px 8px; */
  border-radius: 3px;
  border: 1px solid RGB(227, 231, 235);
  padding: 10px 24px 10px 10px;
}

.whiteFieldContainer2nd {
  margin-top: -4rem;
  width: 75%;

  padding-right: 0.7rem;
}

.darListColumn {
  /* color: green */
  font-size: 10pt;
  text-align: center;
}

.modalColumn {
  border-bottom: 1px SOLID #80b634;
  border-right: 1px SOLID #80b634;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  border: 2px solid red;
}
.css-1rhbuit-multiValue {
  z-index: 0;
}

.field-note-img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.border-bottom-dotted {
  border: 1px dashed rgba(112, 112, 112, 0.1);
  width: 100%;
  margin: 10px 0;
}

@media only screen and (max-width: 1380px) {
  .whiteFieldContainer2nd {
    /* margin-top: 3rem; */
    width: 75%;
    /* padding-bottom: 20rem; */
    /* padding-left: 0 */
  }
}
@media only screen and (max-width: 970px) {
  .whiteFieldContainer2nd {
    margin-top: 3rem;
    width: 100%;
    padding-bottom: 20rem;
    padding-left: 0;
  }
}

#content {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

#content figure {
  margin: 0;
}

.react-3d-carousel {
  width: 400px;
  height: 300px;
  position: relative;
  perspective: 1000px;
}

.react-3d-carousel .carousel {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
}

.react-3d-carousel .carousel figure {
  display: block;
  position: absolute;
  width: 400px;
  height: 300px;
  left: 10px;
  top: 10px;
  border: 2px solid black;
  line-height: 116px;
  font-size: 80px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.react-3d-carousel .carousel figure {
  background: hsla(160, 100%, 50%, 0.8);
}

#content table {
  position: absolute;
  top: 400px;
  width: 400px;
}

.react-3d-carousel .prev {
  position: absolute;
  height: 100%;
  width: 15%;
  top: 0px;
  left: 0px;
}

.react-3d-carousel .prev:before {
  content: url('https://s3.postimg.org/o6xpvkidb/chevron_left_white.png');
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 43px;
  height: 49px;
  margin: auto;
}

.react-3d-carousel .next {
  position: absolute;
  height: 100%;
  width: 15%;
  top: 0px;
  right: -5px;
}

.react-3d-carousel .next:before {
  content: url('https://s2.postimg.org/l0n6eaoad/chevron_right_white.png');
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 43px;
  height: 49px;
  margin: auto;
}

li.dot {
  background-color: #6d6d6d;
}

.carousel .control-dots {
  margin-top: 1rem;
}
.carousel .control-dots .dot {
  background-color: #6d6d6d;
}
.carousel .control-dots .dot.selected {
  background: linear-gradient(68.76deg, #81b734 6.64%, #97ed1e 145.11%);
  border-radius: 10px;
  width: 45px;
  transition: background 0.3s ease, border-radius 0.3s ease, width 0.4s ease;
}

#zoom-level {
  zoom: 95%;
}

.progress {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.tooltip {
  /* border: solid 1px #e0e0e0; */
}

div.ant-popover-title {
  margin-bottom: 0 !important;
}
div.ant-popover-inner {
  padding: 1px !important;
}

.calendarPayment {
  width: 270px;
  height: 294px;
  /* Set the width to your desired value */
}

div.rdrMonth {
  /* width: 100%; */
  width: 270px;
}
/* .rdrCalendarWrapper {
border: 2px solid red;
} */

.calendarPayment {
  box-shadow: 0px 13px 20px 10px #00000005 !important;
  border: 1px solid #a179f233 !important;
  border-radius: 6px;
}

.ant-popover {
  position: fixed !important;
}
